import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, vShow as _vShow, mergeProps as _mergeProps, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import vueRecaptcha from 'vue3-recaptcha2';
import { computed, nextTick, ref, watch } from 'vue';

const loadingTimeout = 30000; // 30 seconds


export default /*@__PURE__*/_defineComponent({
  __name: 'InvisibleGoogleCaptcha',
  emits: ['closed', 'onload', 'verify', 'fail', 'error', 'expired', 'processing'],
  setup(__props, { expose: __expose, emit: __emit }) {

// @ts-ignore
const captcha = ref();
const captchaStatus = ref('initial');
const captchaRetries = ref(0)
const sitekey = computed(() => process.env.VUE_APP_CAPTCHA_SITE_KEY ?? '');
__expose({
  execute: async () => {
    const iframe = checkLoad()
    if(iframe){
      try {
        captcha.value?.execute();
        processing.value = true;
      } catch (err) {
        console.log(err)
      }
    } else {
      captchaRetries.value++
      if(captchaRetries.value > 100){
        recaptchaVerified('success')
        captchaRetries.value = 0;
      } else {
        emit('fail', 'loading')
      }
    }
  },
  captchaStatus: captchaStatus
});
const emit = __emit;

const showRecaptcha = ref(false);
const processing = ref(false);
function checkLoad(){
  const iframe = document.querySelector('body > div:last-of-type > div:last-of-type > iframe[title*="recaptcha"]')
  return !!iframe
}

async function recaptchaVerified(response : any) {
  processing.value = false;
  captchaStatus.value = 'success';
  emit('verify', response)
}

function recaptchaExpired() {
  captchaStatus.value = 'expired';
  emit('expired')
  captcha.value.reset();
}

function recaptchaFailed(response : any) {
  processing.value = false;
  captchaStatus.value = 'fail';
  emit('fail', response)
}

function recaptchaError(reason : any) {
  processing.value = false;
  captchaStatus.value = 'error';
  emit('error', reason)
}

function challengeClosed(){
  emit('closed')  
}

watch(()=>captchaStatus.value, (newValue, oldValue)=> {
  if(oldValue === 'initial' && newValue === 'error'){
    console.log('captcha loading error')
    captchaStatus.value = 'success'
  }
  else if(oldValue === 'initial' && newValue === 'fail'){
    console.log('captcha error')
    captchaStatus.value = 'success'
  }
}, {immediate: true})

/** setting challange middle-screen */
watch(() => processing.value, async (newValue) => {
if(newValue){
  const iframe = checkLoad()
  if(iframe){
    await nextTick()
    const el = document.querySelector('body > div:last-of-type > div:last-of-type') as HTMLElement;
    const wrapper = document.querySelector('body > div:last-of-type') as HTMLElement;
    el.style.top = '50%';
    el.style.transform = 'translateY(-50%)';
    wrapper.addEventListener('click', () => {
      challengeClosed()
    })
  }
} 
});

return (_ctx: any,_cache: any) => {
  return _withDirectives((_openBlock(), _createBlock(_unref(vueRecaptcha), _mergeProps({
    sitekey: sitekey.value,
    size: "invisible",
    theme: "light",
    hl: "en",
    "loading-timeout": loadingTimeout,
    onVerify: recaptchaVerified,
    onExpire: recaptchaExpired,
    onFail: recaptchaFailed,
    onError: recaptchaError,
    ref_key: "captcha",
    ref: captcha
  }, _ctx.$attrs, { class: "myCaptcha" }), null, 16, ["sitekey"])), [
    [_vShow, showRecaptcha.value]
  ])
}
}

})